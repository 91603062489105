import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

interface INavigationContextProps {
  values: {
    previousPath: string
    navigationText: string
  }
  actions: {
    setNavigationText: Dispatch<SetStateAction<string>>
  }
}

const NavigationContext = createContext<INavigationContextProps>({
  values: {
    previousPath: '',
    navigationText: '',
  },
  actions: {
    setNavigationText: () => {},
  },
})

export const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const { asPath } = useRouter()

  // used for custom navigation text where the data can't be accessed e.g "Back to <child's name>" on reports page
  const [navigationText, setNavigationText] = useState('')

  const ref = useRef<string | null>(null)

  useEffect(() => {
    ref.current = asPath
  }, [asPath])

  return (
    <NavigationContext.Provider
      value={{
        values: {
          previousPath: ref.current ?? '',
          navigationText,
        },
        actions: {
          setNavigationText,
        },
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigationContext = () => useContext(NavigationContext)
