import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { fallbackOpDays, getOpDays } from 'common/utils/operating-days-utils'
import { useServiceCurrentUserInfo } from 'services/use-service-current-user-info'
import { useServiceRooms } from 'services/use-service-rooms'
import {
  GetCurrentUserQuery,
  GetRoomsQuery,
  GetServiceInfoQuery,
  Job,
  Organisation,
} from 'generated/moments-graphql/schema'
import { useServiceServiceInfo } from 'services/use-service-service-info'
import { useServiceOrganisationInfo } from 'services/use-service-organisation-info'

export type TMe = GetCurrentUserQuery['me']
export type TRoom = GetRoomsQuery['rooms'][0]
export type TServiceInfo = GetServiceInfoQuery['service']

interface IAppContextProps {
  userJobCtx: {
    userId: number | undefined
    userJob: Job | undefined
  }
  userInfoCtx: {
    userInfo: TMe | undefined
    isUserInfoLoading: boolean
  }
  roomCtx: {
    rooms: TRoom[]
    selectedRoom: TRoom | undefined
    setSelectedRoom: Dispatch<SetStateAction<TRoom | undefined>>
  }
  serviceInfoCtx: {
    serviceInfo: TServiceInfo | undefined
    isServiceInfoLoading: boolean
    operatingDays: string[]
  }
  orgInfoCtx: {
    organisationInfo: Organisation | undefined
    isOrgInfoLoading: boolean
    logoSrc: string | undefined
  }
}

const AppContext = createContext<IAppContextProps>({
  userJobCtx: { userId: undefined, userJob: undefined },
  userInfoCtx: { userInfo: undefined, isUserInfoLoading: true },
  roomCtx: { rooms: [], selectedRoom: undefined, setSelectedRoom: () => {} },
  serviceInfoCtx: { serviceInfo: undefined, isServiceInfoLoading: true, operatingDays: fallbackOpDays },
  orgInfoCtx: { organisationInfo: undefined, isOrgInfoLoading: true, logoSrc: undefined },
})

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const { srvid } = useRouter().query

  const { FetchUserInfo } = useServiceCurrentUserInfo()
  const { FetchRooms } = useServiceRooms()

  const { data: userInfo, isLoading: isUserInfoLoading } = FetchUserInfo()
  const userId = Number(userInfo?.id)
  const dataJobInfo = useMemo(() => {
    return userInfo?.jobs ?? []
  }, [userInfo])

  const { data: dataRooms } = FetchRooms({
    serviceId: srvid as string,
  })

  //#region - User job context
  const userJob = useMemo(() => {
    return dataJobInfo?.find((job) => job?.serviceId?.toString() === srvid) ?? undefined
  }, [srvid, dataJobInfo])
  //#endregion

  //#region - Rooms context
  const [selectedRoom, setSelectedRoom] = useState<TRoom>()

  const rooms = useMemo(() => {
    let rooms =
      dataRooms
        ?.filter((room) => room.serviceId === srvid && !room.inactive && room?.roomType === 'contact')
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })) || []
    return rooms
  }, [srvid, dataRooms])
  //#endregion

  //#region - Service Info context
  const { FetchServiceInfo } = useServiceServiceInfo()
  const { data: serviceInfo, isLoading: isServiceInfoLoading } = FetchServiceInfo({ id: parseInt(srvid as string) })
  const operatingDays = useMemo(() => getOpDays(serviceInfo), [serviceInfo])
  //#endregion

  //#region - Organisation Info context
  const { FetchOrgInfo } = useServiceOrganisationInfo()
  const { data: organisationInfo, isLoading: isOrgInfoLoading } = FetchOrgInfo({
    id: serviceInfo?.organisationId as number,
  })
  const logoSrc = useMemo(() => {
    if (organisationInfo?.logoData) {
      const logoId = JSON.parse(organisationInfo.logoData)
      return process.env.NEXT_PUBLIC_BASE_URL + '/uploads/store/' + logoId.id
    }
    return undefined
  }, [organisationInfo])
  //#endregion

  const value = {
    userJobCtx: { userId, userJob },
    userInfoCtx: { userInfo, isUserInfoLoading },
    roomCtx: { rooms, selectedRoom, setSelectedRoom },
    serviceInfoCtx: { serviceInfo, isServiceInfoLoading, operatingDays },
    orgInfoCtx: { organisationInfo, isOrgInfoLoading, logoSrc },
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
